import Vue from 'vue';
import VueRouter from 'vue-router';
import store from './main';
import ChangeRoles from './components/bulkchanges/ChangeRoles';

const Login = () => import('./components/Login.vue');
const Landing = () => import('./components/Landing.vue');
const Find = () => import('./components/Find.vue');
const Create = () => import('./components/bulkchanges/Create.vue');
const Edit = () => import('./components/Edit.vue');
const EditExternal = () => import('./components/EditExternal.vue');
const FindEntitlement = () => import('./components/FindEntitlement.vue');
const CodeRedemptionViewOffers = () =>
  import('./components/coderedemption/CodeRedemptionViewOffers.vue');
const CodeRedemptionOffer = () =>
  import('./components/coderedemption/CodeRedemptionOffer.vue');
const CodeGeneration = () =>
  import('./components/coderedemption/CodeGeneration.vue');
const RedemptionOffers = () => import('./components/redemption/Offers.vue');
const OfferDetails = () => import('./components/redemption/OfferDetails.vue');
const Audit = () => import('./components/Audit.vue');
const Developer = () => import('./components/developertools/Domains.vue');
const DomainDetails = () =>
  import('./components/developertools/DomainDetails.vue');
const GameDetails = () => import('./components/developertools/GameDetails.vue');
const ClientDetails = () =>
  import('./components/developertools/ClientDetails.vue');
const GameRoleDetails = () =>
  import('./components/developertools/GameRoleDetails.vue');
const DomainRoleDetails = () =>
  import('./components/developertools/DomainRoleDetails.vue');
const BrazeDelete = () => import('./components/braze/Delete.vue');
const BulkRoles = () => import('./components/bulkchanges/ChangeRoles.vue');
const Social = () => import('./components/social/Social.vue');
const FriendshipResults = () =>
  import('./components/social/FriendshipResults.vue');
const SearchRoles = () => import('./components/searchRoles/SearchRoles.vue');
const ThirdPartyDetails = () =>
  import('./components/developertools/ThirdPartyDetails.vue');
const ParentalConsentProviderDetails = () =>
  import('./components/developertools/ParentalConsentProviderDetails.vue');

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    component: Landing,
    beforeEnter: requireAuth,
  },
  {
    path: '/login',
    component: Login,
  },
  {
    path: '/landing',
    component: Landing,
    beforeEnter: requireAuth,
    meta: {
      breadCrumb: [{ text: 'Home', href: '/landing' }],
    },
  },
  {
    path: '/braze',
    component: BrazeDelete,
    beforeEnter: requireAuth,
    meta: {
      breadCrumb: [
        { text: 'Home', href: '/landing' },
        { text: 'Braze', href: '/braze' },
      ],
    },
  },
  {
    path: '/find',
    component: Find,
    beforeEnter: requireAuth,
    meta: {
      breadCrumb: [
        { text: 'Home', href: '/landing' },
        { text: 'Accounts', href: '/find' },
      ],
    },
  },
  {
    path: '/find/:domainID',
    component: Find,
    props: true,
    beforeEnter: requireAuth,
    meta: {
      breadCrumb: [
        { text: 'Home', href: '/landing' },
        { text: 'Accounts', href: '/find' },
        { text: ':domainID', href: '/find/:domainID' },
      ],
    },
  },
  {
    path: '/create',
    component: Create,
    beforeEnter: requireAuth,
    meta: {
      breadCrumb: [
        { text: 'Home', href: '/landing' },
        { text: 'Create Accounts', href: '/create' },
      ],
    },
  },
  {
    path: '/edit',
    component: Edit,
    beforeEnter: requireAuth,
    meta: {
      breadCrumb: [
        { text: 'Home', href: '/landing' },
        { text: 'Accounts', href: '/find' },
      ],
    },
  },
  {
    path: '/edit/:domain/:account',
    component: Edit,
    props: true,
    beforeEnter: requireAuth,
    meta: {
      breadCrumb: [
        { text: 'Home', href: '/landing' },
        { text: 'Accounts', href: '/find' },
        { text: ':domain', href: '/find/:domain' },
        { text: ':account', href: '/edit/:domain/:account' },
      ],
    },
  },
  {
    path: '/editexternal',
    component: EditExternal,
    beforeEnter: requireAuth,
    meta: {
      breadCrumb: [
        { text: 'Home', href: '/landing' },
        { text: 'Edit External Persona', href: '/editexternal' },
      ],
    },
  },
  {
    path: '/editexternal/:domain/:persona',
    component: EditExternal,
    props: true,
    beforeEnter: requireAuth,
    meta: {
      breadCrumb: [
        { text: 'Home', href: '/landing' },
        { text: 'Edit External Persona', href: '/editexternal' },
        { text: ':domain', href: '/editexternal/:domain/:account' },
        { text: ':account', href: '/editexternal/:domain/:account' },
      ],
    },
  },
  {
    path: '/entitlements',
    component: FindEntitlement,
    beforeEnter: requireAuth,
    meta: {
      breadCrumb: [
        { text: 'Home', href: '/landing' },
        { text: 'Entitlements', href: '/entitlements' },
      ],
    },
  },
  {
    path: '/coderedemption',
    component: CodeRedemptionViewOffers,
    beforeEnter: requireAuth,
    meta: {
      breadCrumb: [
        { text: 'Home', href: '/landing' },
        { text: 'Code Redemption', href: 'coderedemption' },
      ],
    },
  },
  {
    path: '/coderedemption/offers',
    component: CodeRedemptionViewOffers,
    beforeEnter: requireAuth,
    meta: {
      breadCrumb: [
        { text: 'Home', href: '/landing' },
        { text: 'Code Redemption', href: '/coderedemption' },
        { text: 'Offers', href: '/coderedemption/offers' },
      ],
    },
  },
  {
    path: '/coderedemption/offers/:offerId',
    component: CodeRedemptionOffer,
    props: true,
    beforeEnter: requireAuth,
    meta: {
      breadCrumb: [
        { text: 'Home', href: '/landing' },
        { text: 'Code Redemption', href: '/coderedemption' },
        { text: 'Offers', href: '/coderedemption/offers' },
        { text: ':offerId', href: '/coderedemption/offers/:offerId' },
      ],
    },
  },
  {
    path: '/coderedemption/generatecodes/:offerId',
    component: CodeGeneration,
    props: true,
    beforeEnter: requireAuth,
    meta: {
      breadCrumb: [
        { text: 'Home', href: '/landing' },
        { text: 'Code Redemption', href: '/coderedemption' },
        { text: 'Offers', href: '/coderedemption/offers' },
        { text: ':offerId', href: '/coderedemption/offers/:offerId' },
        {
          text: 'Generate Codes',
          href: '/coderedemption/generatecodes/:offerId',
        },
      ],
    },
  },
  {
    path: '/redemption/offers',
    component: RedemptionOffers,
    props: true,
    beforeEnter: requireAuth,
    meta: {
      breadCrumb: [{ text: 'Home', href: '/landing' }, { text: 'Offers' }],
    },
  },
  {
    path: '/redemption/offers/new',
    component: OfferDetails,
    beforeEnter: requireAuth,
    meta: {
      breadCrumb: [
        { text: 'Home', href: '/landing' },
        { text: 'Offers', href: '/redemption/offers' },
        { text: 'New', href: '/redemption/offers/new' },
      ],
    },
  },
  {
    path: '/redemption/offers/:offerID',
    component: OfferDetails,
    props: true,
    beforeEnter: requireAuth,
    meta: {
      breadCrumb: [
        { text: 'Home', href: '/landing' },
        { text: 'Offers', href: '/redemption/offers' },
        { text: ':offerID', href: '/redemption/offers/:offerID' },
      ],
    },
  },
  {
    path: '/audit',
    component: Audit,
    beforeEnter: requireAuth,
    meta: {
      breadCrumb: [
        { text: 'Home', href: '/landing' },
        { text: 'Audit', href: '/audit' },
      ],
    },
  },
  {
    path: '/developer',
    component: Developer,
    beforeEnter: requireAuth,
    meta: {
      breadCrumb: [
        { text: 'Home', href: '/landing' },
        { text: 'Developer Tools', href: '/developer' },
      ],
    },
  },
  {
    path: '/developer/domain/:domainID',
    component: DomainDetails,
    beforeEnter: requireAuth,
    props: true,
    meta: {
      breadCrumb: [
        { text: 'Home', href: '/landing' },
        { text: 'Developer Tools', href: '/developer' },
        { text: 'Domain :domainID', href: '/developer/domain/:domainID' },
      ],
    },
  },
  {
    path: '/developer/createdomain',
    component: DomainDetails,
    beforeEnter: requireAuth,
    props: true,
    meta: {
      breadCrumb: [
        { text: 'Home', href: '/landing' },
        { text: 'Developer Tools', href: '/developer' },
        { text: 'Create Domain', href: '/developer/createdomain' },
      ],
    },
  },
  {
    path: '/developer/domain/:domainID/game/:gameID',
    component: GameDetails,
    beforeEnter: requireAuth,
    props: true,
    meta: {
      breadCrumb: [
        { text: 'Home', href: '/landing' },
        { text: 'Developer Tools', href: '/developer' },
        { text: 'Domain :domainID', href: '/developer/domain/:domainID' },
        {
          text: 'Game :gameID',
          href: '/developer/domain/:domainID/game/:gameID',
        },
      ],
    },
  },
  {
    path: '/developer/domain/:domainID/creategame',
    component: GameDetails,
    beforeEnter: requireAuth,
    props: true,
    meta: {
      breadCrumb: [
        { text: 'Home', href: '/landing' },
        { text: 'Developer Tools', href: '/developer' },
        { text: 'Domain :domainID', href: '/developer/domain/:domainID' },
        { text: 'Create Game', href: '/developer/domain/:domainID/creategame' },
      ],
    },
  },
  {
    path: '/developer/domain/:domainID/game/:gameID/client/:clientID',
    component: ClientDetails,
    beforeEnter: requireAuth,
    props: true,
    meta: {
      breadCrumb: [
        { text: 'Home', href: '/landing' },
        { text: 'Developer Tools', href: '/developer' },
        { text: 'Domain :domainID', href: '/developer/domain/:domainID' },
        {
          text: 'Game :gameID',
          href: '/developer/domain/:domainID/game/:gameID',
        },
        {
          text: 'Client :clientID',
          href: '/developer/domain/:domainID/game/:gameID/client/:clientID',
        },
      ],
    },
  },
  {
    path:
      '/developer/domain/:domainID/game/:gameID/thirdPartyClient/:identityProvider',
    component: ThirdPartyDetails,
    beforeEnter: requireAuth,
    props: true,
    meta: {
      breadCrumb: [
        { text: 'Home', href: '/landing' },
        { text: 'Developer Tools', href: '/developer' },
        { text: 'Domain :domainID', href: '/developer/domain/:domainID' },
        {
          text: 'Game :gameID',
          href: '/developer/domain/:domainID/game/:gameID/client/:clientID',
        },
      ],
    },
  },
  {
    path: '/developer/domain/:domainID/game/:gameID/createThirdParty',
    component: ThirdPartyDetails,
    beforeEnter: requireAuth,
    props: true,
    meta: {
      breadCrumb: [
        { text: 'Home', href: '/landing' },
        { text: 'Developer Tools', href: '/developer' },
        { text: 'Domain :domainID', href: '/developer/domain/:domainID' },
        {
          text: 'Game :gameID',
          href: '/developer/domain/:domainID/game/:gameID',
        },
        {
          text: 'Client',
          href: '/developer/domain/:domainID/game/:gameID/createThirdParty',
        },
      ],
    },
  },
  {
    path:
      '/developer/domain/:domainID/game/:gameID/parental-consent-provider/:parentalConsentProvider',
    component: ParentalConsentProviderDetails,
    beforeEnter: requireAuth,
    props: true,
    meta: {
      breadCrumb: [
        { text: 'Home', href: '/landing' },
        { text: 'Developer Tools', href: '/developer' },
        { text: 'Domain :domainID', href: '/developer/domain/:domainID' },
        {
          text: 'Game :gameID',
          href: '/developer/domain/:domainID/game/:gameID',
        },
      ],
    },
  },
  {
    path:
      '/developer/domain/:domainID/game/:gameID/create-parental-consent-provider',
    component: ParentalConsentProviderDetails,
    beforeEnter: requireAuth,
    props: true,
    meta: {
      breadCrumb: [
        { text: 'Home', href: '/landing' },
        { text: 'Developer Tools', href: '/developer' },
        { text: 'Domain :domainID', href: '/developer/domain/:domainID' },
        {
          text: 'Game :gameID',
          href: '/developer/domain/:domainID/game/:gameID',
        },
        {
          text: 'Parental Consent Provider',
          href:
            '/developer/domain/:domainID/game/:gameID/create-parental-consent-provider',
        },
      ],
    },
  },
  {
    path: '/developer/domain/:domainID/game/:gameID/createclient',
    component: ClientDetails,
    beforeEnter: requireAuth,
    props: true,
    meta: {
      breadCrumb: [
        { text: 'Home', href: '/landing' },
        { text: 'Developer Tools', href: '/developer' },
        { text: 'Domain :domainID', href: '/developer/domain/:domainID' },
        {
          text: 'Game :gameID',
          href: '/developer/domain/:domainID/game/:gameID',
        },
        {
          text: 'Client',
          href: '/developer/domain/:domainID/game/:gameID/createclient',
        },
      ],
    },
  },
  {
    path: '/developer/domain/:domainID/game/:gameID/role/:roleID',
    component: GameRoleDetails,
    beforeEnter: requireAuth,
    props: true,
    meta: {
      breadCrumb: [
        { text: 'Home', href: '/landing' },
        { text: 'Developer Tools', href: '/developer' },
        { text: 'Domain :domainID', href: '/developer/domain/:domainID' },
        {
          text: 'Game :gameID',
          href: '/developer/domain/:domainID/game/:gameID',
        },
        {
          text: 'Role',
          href: '/developer/domain/:domainID/game/:gameID/role/:roleID',
        },
      ],
    },
  },
  {
    path: '/developer/domain/:domainID/game/:gameID/createrole',
    component: GameRoleDetails,
    beforeEnter: requireAuth,
    props: true,
    meta: {
      breadCrumb: [
        { text: 'Home', href: '/landing' },
        { text: 'Developer Tools', href: '/developer' },
        { text: 'Domain :domainID', href: '/developer/domain/:domainID' },
        {
          text: 'Game :gameID',
          href: '/developer/domain/:domainID/game/:gameID',
        },
        {
          text: 'Role',
          href: '/developer/domain/:domainID/game/:gameID/createrole',
        },
      ],
    },
  },
  {
    path: '/developer/domain/:domainID/role/:roleID',
    component: DomainRoleDetails,
    beforeEnter: requireAuth,
    props: true,
    meta: {
      breadCrumb: [
        { text: 'Home', href: '/landing' },
        { text: 'Developer Tools', href: '/developer' },
        { text: 'Domain :domainID', href: '/developer/domain/:domainID' },
        {
          text: 'Role :roleID',
          href: '/developer/domain/:domainID/role/:roleID',
        },
      ],
    },
  },
  {
    path: '/developer/domain/:domainID/createrole',
    component: DomainRoleDetails,
    beforeEnter: requireAuth,
    props: true,
    meta: {
      breadCrumb: [
        { text: 'Home', href: '/landing' },
        { text: 'Developer Tools', href: '/developer' },
        { text: 'Domain :domainID', href: '/developer/domain/:domainID' },
        { text: 'Role', href: '/developer/domain/:domainID/createrole' },
      ],
    },
  },
  {
    path: '/bulkroles',
    component: BulkRoles,
    beforeEnter: requireAuth,
    meta: {
      breadCrumb: [
        { text: 'Home', href: '/landing' },
        { text: 'Bulk Roles', href: '/bulkroles' },
      ],
    },
  },
  {
    path: '/social',
    component: Social,
    beforeEnter: requireAuth,
    meta: {
      breadCrumb: [
        { text: 'Home', href: '/landing' },
        { text: 'Social Tools', href: '/social' },
      ],
    },
  },
  {
    path: '/social/:accountID',
    component: FriendshipResults,
    props: true,
    beforeEnter: requireAuth,
    meta: {
      breadCrumb: [
        { text: 'Home', href: '/landing' },
        { text: 'Social Tools', href: '/social' },
        { text: 'Details', href: '/social/:accountID' },
      ],
    },
  },
  {
    path: '/search-roles',
    component: SearchRoles,
    beforeEnter: requireAuth,
    meta: {
      breadCrumb: [
        { text: 'Home', href: '/landing' },
        { text: 'Search Roles', href: '/search-roles' },
      ],
    },
  },
];

export default new VueRouter({
  routes: routes,
  mode: 'history',
});

function requireAuth(to, from, next) {
  if (store.getters.authenticated) {
    next();
  } else {
    store
      .dispatch('refreshIfNeeded')
      .then(() => {
        //successful refresh
        next();
      })
      .catch((err) => {
        store.commit('clear');
        //error refreshing, logout.
        let cleanedPath = encodeURI(to.fullPath);
        cleanedPath = cleanedPath.replace(/&/g, encodeURIComponent('&'));
        next(`/login?redirectTo=${cleanedPath}`);
      });
  }
}

function getRedirect() {
  return this.getUrlParam('redirectTo', '');
}

function getUrlVars() {
  var vars = {};

  var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (
    m,
    key,
    value
  ) {
    vars[key] = value;
  });

  return vars;
}

function getUrlParam(parameter, defaultvalue) {
  var urlparameter = defaultvalue;

  if (window.location.href.indexOf(parameter) > -1) {
    urlparameter = getUrlVars()[parameter];
  }

  return urlparameter;
}
